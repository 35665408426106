import { lazy } from "react";
import { RouteType } from "../types/routes";
import { ROUTES } from "../constants/routes";
import { USER_ROLES } from "../constants/roles";

const PageNotFound = lazy(() => import("../pages/PageNotFound"));
const Login = lazy(() => import("../pages/Login"));
const VerifyEmail = lazy(() => import("../pages/VerifyEmail"));
// const ForgotPassword = lazy(() => import("../pages/ForgotPassword"));
// const ResetPassword = lazy(() => import("../pages/ResetPassword"));
// const UpdatePassword = lazy(() => import("../pages/UpdatePassword"));
const Home = lazy(() => import("../pages/Home"));
const Signup = lazy(() => import("../pages/Signup"));
const Training = lazy(() => import("../pages/Training"));
const PersonalReflection = lazy(() => import("../pages/PersonalReflection"));
const SchoolReflection = lazy(() => import("../pages/SchoolReflection"));
// const Community = lazy(() => import("../pages/Community"));
const SelfGuidedTraining = lazy(() => import("../pages/SelfGuidedTraining"));
const LiveTraining = lazy(() => import("../pages/LiveTraining"));
const StudentCurriculum = lazy(() => import("../pages/StudentCurriculum"));
const MediaPlayer = lazy(() => import("../pages/MediaPlayer"));
const Profile = lazy(() => import("../pages/Profile"));
const PersonalInfo = lazy(() => import("../pages/PersonalInfo"));
const OrganizationInfo = lazy(() => import("../pages/OrganizationInfo"));
const Certificates = lazy(() => import("../pages/Certificates"));
const UpdatePersonalInfo = lazy(() => import("../pages/UpdatePersonalInfo"));
const AccountSettings = lazy(() => import("../pages/AccountSettings"));
const Notifications = lazy(() => import("../pages/Notifications"));
const UpdateOrganizationInfo = lazy(
  () => import("../pages/UpdateOrganizationInfo"),
);
const Settings = lazy(() => import("../pages/Settings"));
const ManageUsers = lazy(() => import("../pages/ManageUsers"));
const WellnessLibrary = lazy(() => import("../pages/WellnessLibrary"));
const LiveTrainingDetails = lazy(() => import("../pages/LiveTrainingDetails"));
const PersonalReflectionArchive = lazy(
  () => import("../pages/PersonalReflectionArchive"),
);
const SchoolReflectionArchive = lazy(
  () => import("../pages/SchoolReflectionArchive"),
);

export const publicPaths: string[] = [
  ROUTES.root,
  ROUTES.verify_email,
  ROUTES.signup,
  ROUTES.forgot_password,
];

export const isPublicPath = (path: string): boolean => {
  return publicPaths.includes(path);
};

export const publicRoutes = [
  {
    path: ROUTES.root,
    element: Login,
    title: "Login",
  },
  {
    path: ROUTES.verify_email,
    element: VerifyEmail,
    title: "Verify Email",
  },
  {
    path: ROUTES.signup,
    element: Signup,
    title: "Signup",
  },
  {
    path: ROUTES.page_not_found,
    element: Login,
    title: "Login",
  },
  // {
  //   path: ROUTES.forgot_password,
  //   element: ForgotPassword,
  //   title: "Forgot Password",
  // },
  // {
  //   path: ROUTES.reset_password,
  //   element: ResetPassword,
  //   title: "Reset Password",
  // },
];

export const privateRoutes = (): RouteType[] => {
  const allUserRoles = Object.values(USER_ROLES);
  return [
    {
      path: ROUTES.root,
      element: Home,
      title: "Home",
      allowedRoles: allUserRoles,
    },
    {
      path: ROUTES.signup,
      element: Signup,
      title: "Signup",
      allowedRoles: [USER_ROLES.ORGANIZATION_ADMIN, USER_ROLES.STAFF_MEMBER],
    },
    {
      path: ROUTES.profile,
      element: Profile,
      title: "Profile",
      allowedRoles: allUserRoles,
    },
    // {
    //   path: ROUTES.update_password,
    //   element: UpdatePassword,
    //   title: "Update Password",
    //   allowedRoles: [],
    // },
    {
      path: ROUTES.notifications,
      element: Notifications,
      title: "Notifications",
      allowedRoles: allUserRoles,
    },
    {
      path: ROUTES.personal_info,
      element: PersonalInfo,
      title: "Personal Info",
      allowedRoles: allUserRoles,
    },
    {
      path: `${ROUTES.edit_personal_info}/:id`,
      element: UpdatePersonalInfo,
      title: "Edit Personal Info",
      allowedRoles: allUserRoles,
    },
    {
      path: ROUTES.organization_info,
      element: OrganizationInfo,
      title: "Organization Info",
      allowedRoles: allUserRoles,
    },
    {
      path: `${ROUTES.edit_organization_info}/:id`,
      element: UpdateOrganizationInfo,
      title: "Edit Organization Info",
      allowedRoles: allUserRoles,
    },
    {
      path: ROUTES.certificates,
      element: Certificates,
      title: "Certificates",
      allowedRoles: allUserRoles,
    },
    {
      path: ROUTES.training,
      element: Training,
      title: "Training",
      allowedRoles: [USER_ROLES.ORGANIZATION_ADMIN, USER_ROLES.STAFF_MEMBER],
    },
    {
      path: ROUTES.reflection,
      element: PersonalReflection,
      title: "Personal Reflection",
      allowedRoles: [USER_ROLES.ORGANIZATION_ADMIN, USER_ROLES.STAFF_MEMBER],
    },
    {
      path: ROUTES.personal_reflection,
      element: PersonalReflection,
      title: "Personal Reflection",
      allowedRoles: [USER_ROLES.ORGANIZATION_ADMIN, USER_ROLES.STAFF_MEMBER],
    },
    {
      path: ROUTES.school_reflection,
      element: SchoolReflection,
      title: "School Reflection",
      allowedRoles: [USER_ROLES.ORGANIZATION_ADMIN, USER_ROLES.STAFF_MEMBER],
    },
    {
      path: ROUTES.self_guided_training,
      element: SelfGuidedTraining,
      title: "Self-Guided Training",
      allowedRoles: [USER_ROLES.ORGANIZATION_ADMIN, USER_ROLES.STAFF_MEMBER],
    },
    {
      path: ROUTES.live_training,
      element: LiveTraining,
      title: "Live Training",
      allowedRoles: [USER_ROLES.ORGANIZATION_ADMIN, USER_ROLES.STAFF_MEMBER],
    },
    {
      path: ROUTES.media_player,
      element: MediaPlayer,
      title: "MediaPlayer",
      allowedRoles: [USER_ROLES.ORGANIZATION_ADMIN, USER_ROLES.STAFF_MEMBER],
    },
    {
      path: ROUTES.teaching,
      element: StudentCurriculum,
      title: "Teaching",
      allowedRoles: [USER_ROLES.ORGANIZATION_ADMIN, USER_ROLES.STAFF_MEMBER],
    },
    {
      path: `${ROUTES.student_curriculum}/:category`,
      element: StudentCurriculum,
      title: "StudentCurriculum",
      allowedRoles: [USER_ROLES.ORGANIZATION_ADMIN, USER_ROLES.STAFF_MEMBER],
    },
    {
      path: `${ROUTES.student_curriculum}/:category/:curriculumId`,
      element: StudentCurriculum,
      title: "StudentCurriculum",
      allowedRoles: [USER_ROLES.ORGANIZATION_ADMIN, USER_ROLES.STAFF_MEMBER],
    },
    // {
    //   path: ROUTES.community,
    //   element: Community,
    //   title: "Community",
    //   allowedRoles: [USER_ROLES.ORGANIZATION_ADMIN, USER_ROLES.STAFF_MEMBER],
    // },
    {
      path: ROUTES.page_not_found,
      element: PageNotFound,
      title: "Page Not Found",
      allowedRoles: allUserRoles,
    },
    {
      path: ROUTES.account_settings,
      element: AccountSettings,
      title: "AccountSettings",
      allowedRoles: allUserRoles,
    },
    {
      path: ROUTES.settings,
      element: Settings,
      title: "Settings",
      allowedRoles: allUserRoles,
    },
    {
      path: ROUTES.manage_users,
      element: ManageUsers,
      title: "ManageUsers",
      allowedRoles: [USER_ROLES.ORGANIZATION_ADMIN],
    },
    {
      path: ROUTES.wellness_library,
      element: WellnessLibrary,
      title: "Wellness Library",
      allowedRoles: allUserRoles,
    },
    {
      path: ROUTES.live_training_details,
      element: LiveTrainingDetails,
      title: "Live Training Details",
      allowedRoles: [USER_ROLES.ORGANIZATION_ADMIN, USER_ROLES.STAFF_MEMBER],
    },
    {
      path: ROUTES.personal_reflection_archive,
      element: PersonalReflectionArchive,
      title: "Personal Reflection Archive",
      allowedRoles: [USER_ROLES.ORGANIZATION_ADMIN, USER_ROLES.STAFF_MEMBER],
    },
    {
      path: ROUTES.school_reflection_archive,
      element: SchoolReflectionArchive,
      title: "School Reflection Archive",
      allowedRoles: [USER_ROLES.ORGANIZATION_ADMIN, USER_ROLES.STAFF_MEMBER],
    },
  ];
};
