import React from "react";
import TourGuideNavigation from "./TourGuideNavigation";
import { TourProvider } from "@reactour/tour";
import TourGuideContent from "./TourGuideContent";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useAuthContext } from "../../hooks/auth-context";
import { USER_ROLES } from "../../constants/roles";

const TourGuideProvider = ({ children }: { children: React.ReactNode }) => {
  const { auth } = useAuthContext();
  const disableBody = (target: Element | null) =>
    disableBodyScroll(target as Element);
  const enableBody = (target: Element | null) =>
    enableBodyScroll(target as Element);

  const steps = [
    {
      selector: '[data-tut="home"]',
      content: (
        <TourGuideContent
          title="Welcome to Your Tour!"
          description="Discover everything the Contentment Foundation's School Platform has to offer. This walkthrough will highlight key features, starting right here on your homepage. Let’s begin!"
        />
      ),
      allowedRoles: [USER_ROLES.ORGANIZATION_ADMIN, USER_ROLES.STAFF_MEMBER],
    },
    {
      selector: '[data-tut="training"]',
      content: (
        <TourGuideContent
          title="Your Training Hub"
          description="Access everything you need to enhance your skills with ease. Dive into Self-Paced Training in your own time or join Live Trainings for interactive learning and real-time connection."
        />
      ),
      allowedRoles: [USER_ROLES.ORGANIZATION_ADMIN, USER_ROLES.STAFF_MEMBER],
    },
    {
      selector: '[data-tut="teaching"]',
      content: (
        <TourGuideContent
          title="Your Teaching Toolkit"
          description="Explore a comprehensive Student Curriculum designed for all age groups, from Infants to High School. This page provides ready-to-use lesson plans to guide you in fostering student wellbeing and engagement."
        />
      ),
      allowedRoles: [USER_ROLES.ORGANIZATION_ADMIN, USER_ROLES.STAFF_MEMBER],
    },
    {
      selector: '[data-tut="reflection"]',
      content: (
        <TourGuideContent
          title="Your Reflection Tool"
          description="The Reflection features a quick wellbeing assessment and personalized feedback, offering real-time insights to guide you toward balance and fulfillment. For best results, complete it monthly!"
        />
      ),
      allowedRoles: [USER_ROLES.ORGANIZATION_ADMIN, USER_ROLES.STAFF_MEMBER],
    },
    // {
    //   selector: '[data-tut="update_profile"]',
    //   content: (
    //     <TourGuideContent
    //       title="Update your Profile"
    //       description="Share a few details about yourself and your school to help us create a personalized experience just for you."
    //     />
    //   ),
    //   allowedRoles: [USER_ROLES.ORGANIZATION_ADMIN, USER_ROLES.STAFF_MEMBER],
    // },
    // {
    //   selector: '[data-tut="Check_in_with_your_wellbeing_reflection"]',
    //   content: (
    //     <TourGuideContent
    //       title="Check-in with your Wellbeing Reflection"
    //       description="Take a few moments to complete your Wellbeing Reflection for quick, meaningful insights on your wellbeing. We suggest checking in monthly to help you build awareness and deepen your personal wellbeing practice."
    //     />
    //   ),
    // },
    // {
    //   selector: '[data-tut="check_out_your_wellbeing_reflection_report"]',
    //   content: (
    //     <TourGuideContent
    //       title="Check out your Wellbeing Reflection Report"
    //       description="Download your Wellbeing Reflection Report for a clear snapshot of your wellbeing. This report provides insights to guide your choices and help you track your wellbeing journey over time."
    //     />
    //   ),
    // },
    // {
    //   selector: '[data-tut="complete_your_self_paced_foundations_training"]',
    //   content: (
    //     <TourGuideContent
    //       title="Complete your Self-Paced Foundations Training"
    //       description="Dive into the Foundations of Wellbeing training—a self-guided introduction to the Four Pillars of Wellbeing. Through interactive lessons, videos, and journal prompts, you'll gain a flexible, in-depth understanding of each pillar to support your personal and professional growth."
    //     />
    //   ),
    // },
    // {
    //   selector: '[data-tut="join_a_live_training"]',
    //   content: (
    //     <TourGuideContent
    //       title="Join a Live Training"
    //       description="Experience our Live Training sessions, where you’ll join a community to explore the Four Pillars of Wellbeing together. Through interactive, live sessions, you’ll deepen your understanding, share insights, and practice wellbeing techniques in a supportive and collaborative environment."
    //     />
    //   ),
    // },
    // {
    //   selector: '[data-tut="foster_student_wellbeing_with_the_four_pillars"]',
    //   content: (
    //     <TourGuideContent
    //       title="Foster Student Wellbeing with the Four Pillars"
    //       description="Empower your students with the Four Pillars of Wellbeing! The Student Curriculum provides lesson plans filled with essential tools and practices to support their emotional, social, and mental health. By integrating these lessons, you’ll create a nurturing environment that promotes personal growth, resilience, and a lasting foundation in wellbeing."
    //     />
    //   ),
    // },
    {
      selector: '[data-tut="invite_your_staff"]',
      content: (
        <TourGuideContent
          title="Invite your Staff"
          description="Invite your staff to join the wellbeing journey! By sending out invitations, you empower your staff to explore the Four Pillars of Wellbeing and enhance their personal and professional growth."
        />
      ),
      allowedRoles: [USER_ROLES.ORGANIZATION_ADMIN],
    },
  ];

  const getFilterSteps = () => {
    return steps?.filter((step) =>
      step.allowedRoles.includes(auth?.default_role as USER_ROLES),
    );
  };

  return (
    <TourProvider
      steps={getFilterSteps()}
      position={"bottom"}
      scrollSmooth={true}
      afterOpen={disableBody}
      beforeClose={enableBody}
      showBadge={false}
      styles={{
        popover: (base) => ({
          ...base,
          backgroundColor: "white",
          paddingTop: "34px",
          paddingBottom: "0px",
          paddingLeft: "0px",
          paddingRight: "0px",
          borderRadius: "2.5%",
          overflow: "hidden",
        }),
        button: () => ({
          color: "black",
        }),
      }}
      components={{
        Navigation: ({ steps, currentStep }) => (
          <TourGuideNavigation steps={steps} currentStep={currentStep} />
        ),
      }}
    >
      {children}
    </TourProvider>
  );
};

export default TourGuideProvider;
