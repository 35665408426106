import { useMemo } from "react";
import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useTheme,
  Divider,
  Image,
  Box,
  Icon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";
import { useNavigate } from "react-router-dom";
// import { useTranslation } from "react-i18next";
import { useThemeContext } from "../hooks/theme-context";
import {
  home,
  homeWhite,
  training,
  trainingWhite,
  reflection,
  reflectionWhite,
  teaching,
  teachingWhite,
  community,
  communityWhite,
  notifications,
  notificationsWhite,
  settings,
  settingsWhite,
  darkMode,
  lightMode,
  logoutIcon,
  logoutWhiteIcon,
} from "../assets";
import { logout } from "../utils/auth-token";
import { ROUTES } from "../constants/routes";
import { CgProfile } from "react-icons/cg";
import { USER_ROLES } from "../constants/roles";
import { NavbarMenuItem } from "../types/navbar";
import { useAuthContext } from "../hooks/auth-context";

interface Props {
  isAuthenticated?: boolean;
  isNavbarRequired?: boolean;
}

// const locales = [
//   { label: "English", value: "en" },
//   { label: "French", value: "fr" },
//   { label: "Spanish", value: "es" },
//   { label: "German", value: "de" },
//   { label: "Chinese", value: "zh" },
//   { label: "Russian", value: "ru" },
//   { label: "Japanese", value: "ja" },
//   { label: "Hindi", value: "hi" },
//   { label: "Arabic", value: "ar" },
// ];

const MobileNavbar = ({
  isAuthenticated = false,
  isNavbarRequired = false,
}: Props) => {
  const theme = useTheme();
  const { colorMode, toggleColorMode } = useThemeContext();
  // const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { auth } = useAuthContext();

  const icon = useMemo(
    () =>
      colorMode === "dark"
        ? {
            home: homeWhite,
            training: trainingWhite,
            reflection: reflectionWhite,
            teaching: teachingWhite,
            community: communityWhite,
            notifications: notificationsWhite,
            settings: settingsWhite,
            logout: logoutWhiteIcon,
            mode: lightMode,
          }
        : {
            home,
            training,
            reflection,
            teaching,
            community,
            notifications,
            settings,
            logout: logoutIcon,
            mode: darkMode,
          },
    [colorMode],
  );

  const allUserRoles = Object.values(USER_ROLES);

  const menuItems: NavbarMenuItem[] = useMemo(
    () => [
      {
        label: "Home",
        icon: icon.home,
        path: ROUTES.root,
        allowedRoles: allUserRoles,
      },
      {
        label: "Training",
        icon: icon.training,
        path: ROUTES.training,
        allowedRoles: allUserRoles,
        subMenu: [
          {
            label: "Self-Guided Training",
            path: ROUTES.self_guided_training,
            allowedRoles: allUserRoles,
          },
          {
            label: "Live Training",
            path: ROUTES.live_training,
            allowedRoles: allUserRoles,
          },
        ],
      },
      {
        label: "Teaching",
        icon: icon.teaching,
        path: ROUTES.teaching,
        allowedRoles: allUserRoles,
      },
      {
        label: "Reflection",
        icon: icon.reflection,
        path: ROUTES.reflection,
        allowedRoles: allUserRoles,
        subMenu: [
          {
            label: "Personal Wellbeing",
            path: ROUTES.personal_reflection,
            allowedRoles: allUserRoles,
          },
          {
            label: "School Wellbeing",
            path: ROUTES.school_reflection,
            allowedRoles: allUserRoles,
          },
        ],
      },
      // {
      //   label: "Community",
      //   icon: icon.community,
      //   path: ROUTES.community,
      // },
    ],
    [allUserRoles, icon],
  );

  const settingsItems = useMemo(() => {
    let items: NavbarMenuItem[] = [
      {
        label: colorMode === "dark" ? "Light Mode" : "Dark Mode",
        icon: icon.mode,
        onClick: toggleColorMode,
        path: "",
      },
    ];
    if (isAuthenticated) {
      items = [
        {
          label: "Profile",
          icon: CgProfile,
          onClick: () => navigate(ROUTES.profile),
          path: ROUTES.profile,
        },
        {
          label: "Notifications",
          icon: icon.notifications,
          onClick: () => navigate(ROUTES.notifications),
          path: ROUTES.notifications,
        },
        {
          label: "Settings",
          icon: icon.settings,
          onClick: () => navigate(ROUTES.settings),
          path: ROUTES.settings,
        },
        {
          label: "Logout",
          icon: icon.logout,
          onClick: async () => {
            await logout();
            window?.location && window.location.reload();
          },
          path: "",
        },
        {
          label: colorMode === "dark" ? "Light Mode" : "Dark Mode",
          icon: icon.mode,
          onClick: toggleColorMode,
          path: "",
        },
      ];
    }
    return items;
  }, [isAuthenticated, colorMode, icon, toggleColorMode, navigate]);

  const filteredMenuItems = useMemo(() => {
    return menuItems
      .filter((item) =>
        item.allowedRoles?.includes(auth?.default_role as USER_ROLES),
      )
      .map((item) => ({
        ...item,
        subMenu: item.subMenu
          ? item.subMenu.filter((subItem) =>
              subItem.allowedRoles?.includes(auth?.default_role as USER_ROLES),
            )
          : undefined,
      }));
  }, [auth?.default_role, menuItems]);

  // const changeLanguage = (lng: string) => {
  //   i18n.changeLanguage(lng);
  // };

  return (
    <Flex
      sx={theme.components.VisibleOnMob.baseStyle}
      justify="flex-start"
      align="center"
    >
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon />}
          variant="outline"
        />
        <MenuList>
          {isNavbarRequired &&
            filteredMenuItems.map((menuItem) => (
              <Box key={menuItem.label}>
                <Accordion allowToggle>
                  <AccordionItem border="none">
                    <AccordionButton
                      onClick={
                        menuItem.subMenu
                          ? undefined
                          : () => navigate(menuItem.path)
                      }
                    >
                      <Box
                        as="span"
                        flex="1"
                        textAlign="left"
                        display="flex"
                        alignItems="center"
                      >
                        <Image
                          src={menuItem.icon as string}
                          boxSize="20px"
                          mr={3}
                        />
                        {menuItem.label}
                      </Box>
                      {menuItem.subMenu && <AccordionIcon />}
                    </AccordionButton>
                    {menuItem.subMenu && (
                      <AccordionPanel pb={4}>
                        {menuItem.subMenu.map((subItem) => (
                          <MenuItem
                            key={subItem.label}
                            onClick={() => navigate(subItem.path)}
                          >
                            {subItem.label}
                          </MenuItem>
                        ))}
                      </AccordionPanel>
                    )}
                  </AccordionItem>
                </Accordion>
              </Box>
            ))}
          {isNavbarRequired && <Divider my={3} />}
          {settingsItems.map((item, index) => (
            <MenuItem
              key={index}
              icon={
                typeof item.icon === "string" ? (
                  <Image src={item.icon} boxSize="20px" />
                ) : (
                  <Icon as={item.icon} boxSize="20px" />
                )
              }
              onClick={item.onClick ? item.onClick : () => navigate(item.path)}
            >
              {item.label}
            </MenuItem>
          ))}
          {/* <Divider my={3} />
          {locales.map((locale) => (
            <MenuItem
              key={locale.value}
              onClick={() => changeLanguage(locale.value)}
            >
              {locale.label}
            </MenuItem>
          ))} */}
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default MobileNavbar;
