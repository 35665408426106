// User roles that are assignable to a user
export enum USER_ROLES {
  ADMIN = "admin",
  ORGANIZATION_ADMIN = "org_admin",
  STAFF_MEMBER = "staff_member",
  // FAMILY_APP_MEMBER = "family_app_member",
}

export enum FORMATTED_ROLES {
  // "admin" = "ADMIN",
  "org_admin" = "ORGANIZATION ADMIN",
  "staff_member" = "STAFF MEMBER",
  // "family_app_member" = "FAMILY APP MEMBER",
}

export enum AVAILABLE_ROLES {
  "admin" = "ADMIN",
  "org_admin" = "ORGANIZATION ADMIN",
  "staff_member" = "STAFF MEMBER",
  // "family_app_member" = "FAMILY APP MEMBER",
}
